<template>
  <s-crud
    title="Categorias de Pago"
    :config="config"
    @save="save($event)"
    edit
    add
    remove
    @rowSelected="rowSelected($event)"
    :filter="filter"    
    ref="crud"
    height="160"
  >
  <!-- FILTRAR POR CULTIVO EN LA VISTA GENERAL -->
    <template v-slot:filter>
      <v-col>
        <v-row justify="center">
          <v-col lg="3" >
            <s-select-definition clearable v-model="filter.TypeCultive" :def="1173" label="Cultivo"> 
            </s-select-definition>
          </v-col>
        </v-row>
      </v-col>
    </template>
  <!-- FIN DEL FILTRO POR CULTIVO -->
    <template slot-scope="props">      
      <v-container
          style="px"
          v-if="props.item != null"
          class="pb-"
          >     
        <v-row justify="left">
          <v-col cols="10" class="s-col-form" sm="3" md="3" lg="3">
            <s-text v-model="props.item.CtgDescription" label="Descripción" autofocus></s-text>
          </v-col>
          <v-col cols="12" class="s-col-form" sm="3" md="3" lg="3">
            <s-select-definition v-model="props.item.TypePaymentCategory" label="Categorías de Pago" :def="1193"></s-select-definition>
          </v-col>
          <v-col cols="12" class="s-col-form" sm="2" md="2" lg="2">
            <s-select-definition v-model="props.item.TypeDiscardCategory" label="Categorías de Descarte" :def="1250"></s-select-definition>
          </v-col>
          <v-col cols="12" class="s-col-form" sm="2" md="2" lg="2">  
            <s-select-definition v-model="props.item.TypeCultive" :def="1173" id='DedValue' label="Cultivo"></s-select-definition>
          </v-col>
          <v-col cols="12" class="s-col-form" sm="2" md="2" lg="2">
            <s-select-definition v-model="props.item.CtgStatus" :def="1220" label="Estado" ></s-select-definition>
          </v-col>    
          <v-col cols="2" class="s-col-form" sm="2" md="2" lg="2">
            <s-select-definition v-model="props.item.TypeLineBusiness " :def="1455" label="Linea de Negocio"></s-select-definition>
          </v-col>     
        </v-row>
      </v-container> 
    </template>
    <!-- <template v-slot:Estado="{ row }">
      <v-chip
        style="margin: 0px"
        x-small
        :color="row.Estado == 'Inactivo' ? 'error' : 'success'"
      >
        {{ row.Estado == 1 ? "Inactivo" : "Activo" }}
      </v-chip>
    </template> -->
    <template v-slot:CtgStatus="{ row }">
      <v-chip class="ma-0" x-small :color="row.CtgStatus == 1 ? 'success' : 'error'">
        {{ row.CtgStatus == 1 ? "Activo" : "Inactivo" }}
      </v-chip>
    </template>

    <template v-slot:SecStatus="{ row }">
      <v-chip class="ma-0" x-small :color="row.SecStatus == 1 ? 'success' : 'error'">
        {{ row.SecStatus == 1 ? "Activo" : "Inactivo" }}
      </v-chip>
    </template>
      
  </s-crud>
</template>


<script>
import TasPaymentCategoryServices from "@/services/Technicalassistance/TasPaymentCategoryService";
import SSelectDefinition from '../../../components/Utils/SSelectDefinition.vue';

export default {
  name: "RequirementServiceTasPaymentCategory",
  components: {SSelectDefinition},
  props: {
    DedID: { type: Number, default: 0 },
    parentID: { type: Number, default: 0 },
    addNoFunction: { type: Boolean, default: false },
  },

  data() {
    return {
      ctgID: 0,
      dialogEdit: false,
      filter: { TypeCultive: 0 , CtgID: 0},
    };
  },

  computed: {
    config() {
      return {
        model: {
          //RETORNAR PROPIEDAD COMPUTADA
          CtgID: "ID",
          CtgDescription: "string",
          PaymentCategoryText: "int",
          TypeDiscardCategoryText: "int",
          CultiveText: "int",
          DedDescription: "string",
          CtgStatus: "status",
          SecStatus: "status",
          Estado: "string",
          //
        },
        service: TasPaymentCategoryServices,
        headers: this.$fun.getSecurity().IsLevelAdmin
          ? [
              //Vista de tabla para admin
              { text: "ID", value: "CtgID", sortable: false },
              { text: "Descripción", value: "CtgDescription", sortable: false },
              { text: "Categoría de pago", value: "PaymentCategoryText", sortable: false },
              { text: "Categoría de Descarte", value: "TypeDiscardCategoryText", sortable: false },
              { text: "Cultivo", value: "CultiveText", sortable: false },
              { text: "Linea de Negocio", value: "TypeLineBusinessText", sortable: false },
              { text: "Estado", value: "CtgStatus", sortable: false },
            ]
          : [
              // CABECERA EN CASO NO SE LOGEE COMO ADMIN
              { text: "ID", value: "CtgID", sortable: false },
              { text: "Descripción", value: "CtgDescription", sortable: false },
              { text: "Categoría de pago", value: "PaymentCategoryText", sortable: false },
              { text: "Categoría de Descarte", value: "TypeDiscardCategoryText", sortable: false },
              { text: "Cultivo", value: "CultiveText", sortable: false },    
              { text: "Linea de Negocio", value: "TypeLineBusinessText", sortable: false },         
              { text: "Estado", value: "CtgStatus", sortable: false },
            ],
      };
    },

    isAdmin() {
      this.$fun.getSecurity().IsLevelAdmin;
    },
  },

  methods: {
    Initialize() {
      this.filter = { DedID: this.DedID };
    },

    edit(item) {
      this.dialogEdit = true;
    },

    rowSelected(items) {
      if (items.length > 0) this.ctgID = items[0].CtgID;
      console.log(items);
      this.$emit("rowSelected", items);
    },

    save(item) {
      item.DedID = this.DedID;
      
      if (this.$fun.getSecurity().IsLevelAdmin) {
        //VALIDACIONES
        if (item.CtgDescription.length == 0) {
          this.$fun.alert("Registre la descripción de la Categoria", "warning");
          return;
        }
        item.save();
      } else {
        if (item.CtgDescription.length == 0) {
          this.$fun.alert("Registre la descripción de la Categoria", "warning");
          return;
        }
        /* if (item.DedID == 0) {
          this.$fun.alert("Seleccione la Definición", "warning");
          return;
        } */
        //item.SecStatus = 1;
        item.save();
      }
    },
  },
  watch: {
    // este DedID es el que esta en variety, vista general en DedID  ="dedID"
    DedID() {
      this.Initialize();
    },
  },
  created() {
    this.filter = { DedID: 0 };
  },
};
</script>
